import thomascooklogo from '../assets/kundelogoer/thomascook.png';
import norgesgruppenlogo from '../assets/kundelogoer/norgesgruppen.png';
import varnerlogo from '../assets/kundelogoer/Varner.png';
import norwegianlogo from '../assets/kundelogoer/norwegian.png';
import getalogo from '../assets/kundelogoer/geta.png';
import bdologo from '../assets/kundelogoer/bdo.png';
import stortingetlogo from '../assets/kundelogoer/stortinget.png';
import sevennlogo from '../assets/kundelogoer/7n.png';
import { motion } from 'framer-motion'
import React from 'react';

const Referanser = () => {
    const logoer = [
        { src: thomascooklogo, alt: "Thomas Cook logo" },
        { src: norgesgruppenlogo, alt: "Norgesgruppen logo" },
        { src: varnerlogo, alt: "Varner logo" },
        { src: norwegianlogo, alt: "Norwegian logo" },
        { src: getalogo, alt: "Geta logo" },
        { src: bdologo, alt: "BDO logo" },
        { src: stortingetlogo, alt: "Stortinget logo" },
        { src: sevennlogo, alt: "7N logo" }
    ]

    return (
        <div className="Referanser">
            <motion.ul
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ staggerChildren: 0.1, delayChildren: 0.5, duration: 0.5 }}
                className="Referanser-kundelogoer">
                {logoer.map((logo, index) => (
                    <motion.li
                        key={index}
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1.0 }}
                        transition={{ delay: 1.5 + index * 0.1, duration: 0.2 }}>
                        <img src={logo.src} alt={logo.alt} />
                    </motion.li>))}
            </motion.ul>
        </div >
    )
}

export default Referanser;